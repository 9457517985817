// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import DoctorList from "@src/views/doctors/store/reducers"
import Modality from "@src/views/Settings/store/reducers"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  DoctorList,
  Modality
})

export default rootReducer
