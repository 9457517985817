// ** Initial State
const initialState = {
  list: {},
  editable: {}
}
const ModalityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MODALITY':
      state.list = !!action.payload ? action.payload : {}
      return state
    case 'ADD_MODALITY':
      state.list = state.list.concat([action.payload])
      return state
    case 'EDIT_MODALITY':
      state.editable = action.payload
      return state
    default:
      return state
  }
}

export default ModalityReducer
